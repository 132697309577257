﻿var GLOBAL_VAR;
var mapType; 
var map_config;

(function($, Drupal, undefined){

  Drupal.behaviors.usa_mapconfig = {
    attach: function(context, settings) {
	    // Initialize Global Variable that is used in map-config.js and map-interact.js
	    GLOBAL_VAR = {
			map_config : {}
		};

      	GLOBAL_VAR.map_config = settings.usamap.variables;

      	var flag = 0;
		var active = 0;
		var activeRegion = [];

		$('.territories').on('click', function() {
			$(this).addClass('active');
			$('.territory-info').hide();
			$('#territory3').show();

			for (var i = 1; i <= 12; i++) {
				if(i == 12)
					$('.region' + i).css('fill', regionhover[12]);
				else 
					$('.region' + i).css('fill', regionhover[13]);
				
			}

			//$('html, body').animate({ scrollTop: $('#territory3').offset().top-200 }, 1000);
			return false;
		});

		$('#regions>path, #regions>polygon, .territories').on('click', function() {

			$('html, body').animate({ scrollTop: $('#accordion').offset().top-200 }, 1000);
			var stateInfo = GLOBAL_VAR.map_config[$(this).attr('id')];
			var regNumber = stateInfo.region;

			// Loop through each of the states and see if it belongs to the same region as the one that was clicked on
			// Depending on which map type it is, it may become opaque or a solid color. See documentation for map types.
			for(var i = 1; i <= 52; i++) {

				// Needed to set regNumber into an array to use the some()
				// By default it is an object
				regNumber = Object.keys(regNumber).map(function(itm) { return regNumber[itm]; });

				GLOBAL_VAR.map_config['map_'+i].region = Object.keys(GLOBAL_VAR.map_config['map_'+i].region).map(function(itm) { return GLOBAL_VAR.map_config['map_'+i].region[itm]; });

				let found = existsInArray(regNumber, GLOBAL_VAR.map_config['map_'+i].region);

				if(mapType == 1) {
					if(found) {
						$('#map_'+i).css({'fill': GLOBAL_VAR.map_config['map_'+i].upColor, 'background-color': GLOBAL_VAR.map_config['map_'+i].upColor});
						$('.map-icons').hide();
						$('#icon_map_'+regNumber).show();
					}
					else {
						$('#map_'+i).css({'fill': GLOBAL_VAR.map_config['map_'+i].downColor, 'background-color': GLOBAL_VAR.map_config['map_'+i].downColor});
					}
				}
				else if(mapType == 2) {
					if(found) {
						$('#map_'+i).css({'fill': GLOBAL_VAR.map_config['map_'+i].downColor, 'background-color': GLOBAL_VAR.map_config['map_'+i].downColor});
					}
					else {
						$('#map_'+i).css({'fill': GLOBAL_VAR.map_config['map_'+i].upColor, 'background-color': GLOBAL_VAR.map_config['map_'+i].upColor});
					}
				}
				
			}

			$('.territory-info').hide();
			$('#territory'+regNumber).show();
			if (!$('#territory'+regNumber).hasClass('opened')) {
				$('#territory'+regNumber).addClass('opened');
			}
			$('#territory'+regNumber).find('.accordion-link').removeClass('collapsed');
			$('#territory'+regNumber).find('.accordion-link').attr("aria-expanded","true");

			var idOfElement = $('#territory'+regNumber).find('.accordion-link').attr("href");
			$(idOfElement).addClass('in');
			$(idOfElement).css('height', 'auto');
			$(idOfElement).attr("aria-expanded","true");

			// The map is now active as a state has been clicked on.
			active = 1;

			// The region of the state that was clicked on is now set as the active region.
			activeRegion = regNumber;	
		});


		// Hover ON functionality
		$('#regions>path, #regions>polygon, .territories').hover(function() {
			var stateInfo = GLOBAL_VAR.map_config[$(this).attr('id')];

			// Needed to set stateInfo.region into an array to use the some()
			// By default it is an object
			stateInfo.region = Object.keys(stateInfo.region).map(function(itm) { return stateInfo.region[itm]; });

			// Check to see if the state being hovered on belongs to the current active region
			let found = existsInArray(stateInfo.region, activeRegion);

			// If map type is typeA, see documentation on map types for more details.
			if(mapType == 1) {
				// If the map is still in its default state and there is no active region, 
				// fill the state with its hover/down color.
				if(activeRegion.length == 0){
					$(this).css({'fill': stateInfo.downColor, 'background-color': stateInfo.downColor});
				}
				// If we are hovering over a state that belongs to the same region that is currently active
				// fill the state with its default color
				else if(found && activeRegion.length) {
					$(this).css({'fill': stateInfo.upColor, 'background-color': stateInfo.upColor});
				}
				// If no regions are currently active,
				// fill the state with its default color 
				else {
					$(this).css({'fill': stateInfo.upColor, 'background-color': stateInfo.upColor});
				}
			}
			// If map type is typeB, see documentation on map types for more details.
			// On hover, will always show its default color
			else if(mapType == 2) {
				$(this).css({'fill': stateInfo.overColor, 'background-color': stateInfo.overColor});
			}
		},
		// Hover OFF functionality
		function() {
			var stateInfo = GLOBAL_VAR.map_config[$(this).attr('id')];
			let found = existsInArray(stateInfo.region, activeRegion);
			// let found = stateInfo.region.some(r=> activeRegion.includes(r))

			// If map type is typeA, see documentation on map types for more details.
			if(mapType == 1) {
				// If the map is still in its default state and there is no active region, 
				// fill the state with its default color.
				if(activeRegion.length == 0){
					$(this).css({'fill': stateInfo.upColor, 'background-color': stateInfo.upColor});
				}
				// If we are hovering over a state that belongs to the same region that is currently active
				// fill the state with its default color
				else if(found && activeRegion.length) {
					$(this).css({'fill': stateInfo.upColor, 'background-color': stateInfo.upColor});
				}
				// If no regions are currently active,
				// fill the state with its hover/down color 
				else {
					$(this).css({'fill': stateInfo.downColor, 'background-color': stateInfo.downColor});

				}
			}
			// If map type is typeB, see documentation on map types for more details.
			else if(mapType == 2) {
				// If we are hovering out of a state that belongs to the same region that is currently active
				// fill the state with its hover/down color
				if(found && activeRegion.length) {
					$(this).css({'fill': stateInfo.downColor, 'background-color': stateInfo.downColor});
				}
				// If no regions are currently active,
				// fill the state with its default color 
				else {
					$(this).css({'fill': stateInfo.upColor, 'background-color': stateInfo.upColor});
				}
			}
		});

		$('#view-all').on('click', function(e) {
			e.preventDefault();
			for(var i = 1; i <= 52; i++) {
				if(mapType == 1) {
					$('#map_'+i).css({'fill': GLOBAL_VAR.map_config['map_'+i].upColor, 'background-color': GLOBAL_VAR.map_config['map_'+i].upColor});
				}
				else if(mapType == 2) {
					$('#map_'+i).css({'fill': GLOBAL_VAR.map_config['map_'+i].downColor, 'background-color': GLOBAL_VAR.map_config['map_'+i].downColor});
				}
			}

			$('.territory-info').show();
			$(".territory-info").each( function () {
				if(!$(this).hasClass('opened')){
					$(this).addClass('opened');
				}
				if(!$(this).find('.panel-collapse').hasClass('in')){
					$(this).find('.panel-collapse').addClass('in');
				}
				$(this).find('.panel-collapse').removeAttr('style');
				
			});
			$('html,body').animate({
				scrollTop: $('#territorycontainer').offset().top - ($('header .header__mainSection').height())
			}, 750)
			$('.map-icons').show();

			// Slide down to the expanded infromation for all the regions
			var target = $(this.hash);
			//$('html, body').animate({ scrollTop: target.offset().top-200 }, 1000);
			return false;
		});

		function existsInArray(arr, val) {
			return arr.some(function(arrVal) {
			    return val == arrVal;
			  });		
		}

		/**
		 * [rgb2hex Convert rgb color value to hex]
		 * @param  {[type]} rgb [rgb value of color]
		 * @return {[type]}     [hex value of color]
		 */
		function rgb2hex(rgb){
		 	rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
		 	return (rgb && rgb.length === 4) ? "#" +
		  	("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
		  	("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
		  	("0" + parseInt(rgb[3],10).toString(16)).slice(-2) : '';
		}
    }
  }
})(jQuery, Drupal);
