'use strict';
var Helpers = require('../core/helpers');
var Modal = require('./modal');

$(".video_play").click(function(e) {
    e.preventDefault();
    e.stopPropagation();


    var videoUrl = $(this).attr('href');
    var videoId = Helpers.getYoutubeUrl(videoUrl);
    if(videoId){
        e.preventDefault();
        var embedHtml = '<iframe src="https://www.youtube.com/embed/'+videoId+'" frameborder="0" allowfullscreen></iframe>';
        this.modal = new Modal(embedHtml, {
            destroy:true,
            video: true
        });
    }

});
