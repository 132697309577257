'use strict';

//var $ = require('jquery');

module.exports = {

    bind: function (Module, $context) {

        var selector = '.' + Module.MODULE_CLASS;

        var $elements = !!$context ? $(selector, $context) : $(selector);

        $elements.each(function () {
            var $el = $(this);
            var instance = new Module($el);
            $el.data('instance', instance);
        });
    },

    body: $('body')
}
