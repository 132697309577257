'use strict';

require('browsernizr/test/css/mediaqueries');
var Modernizr = require('browsernizr');

function Logos($el){
    this.$el = $el;

    this.$img = this.$el.find('.partners__image');
    this.initShift();
}

Logos.prototype.initShift = function(){

    var images = this.getImages();
    var image = images[Math.floor(Math.random()*images.length)];
    var positions = Array(-258,-430,-602,-774, -946, -1118 );
    var position = positions[Math.floor(Math.random()*positions.length)];

    this.$img.attr('src', image);
    if ( Modernizr.mq('(max-width: 767px)') ) {
        this.$el.css('margin-left', position);
    }
};

Logos.prototype.getImages = function(){
    return this.$el.data('images');
};


Logos.MODULE_CLASS = 'partners__image__wrapper';

module.exports = Logos;