module.exports = {

    getYoutubeUrl: function(url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        var match = url.match(regExp);
        return (match&&match[7].length==11)? match[7] : false;
    },

    scrollToHash: function(hashName) {
        $(hashName).scrollIntoView();
    },

    scrollToAnchor: function(element){
        var headerHeight = $('header.header').height();
        $('html, body').animate({
            //animate to the anchored element - the height of the header
            scrollTop: $(element).offset().top - headerHeight
        }, 300); 
    }

};
