'use strict';

var Helpers = require('../core/helpers');

function Page($el){
    this.$el = $el;
    this.$toggleAll = $('body').find('.page__toggle__all-button');
    this.$toggles = this.$el.find('.page__toggle');
    this.$togglesItemCount = this.$el.find('.page__toggle__items-count');
    this.$toggleContent = this.$el.find('.page__toggle__content');
    this.$toggleAll.unbind().on('click', $.proxy(this.onToggleAllClick, this));
    this.$toggles.unbind().on('click', $.proxy(this.onToggleClick, this));

    // Set all pdf's to open in new tab
    this.pdfTargetBlank();
}

Page.prototype.pdfTargetBlank = function(){
    this.$el.find('a[href*="pdf"]').attr('target','_blank');
};

$(document).ready(function() {

    $('.p3sdk-target').find('.p3sdk-progressive-tracking, .p3sdk-container, .p3sdk-interactive-transcript-content').height("auto");
    $('.p3sdk-target').find('.p3sdk-interactive-transcript-content').css("max-height", "250px");

    $('.p3sdk-target').each(function() {
        if($(this).is(':empty')) {
            $(this).parent().css('overflow', 'hidden');
        }
        else {
            $(this).parent().css('overflow', 'scroll');
        }
    });

    $('.p3sdk-target').click(function(){
        var that =  this
        setTimeout(function() {
            // adjust height of transcript container
            $(that).find('.p3sdk-progressive-tracking, .p3sdk-container, .p3sdk-interactive-transcript-content').height("auto");
            $(that).find('.p3sdk-interactive-transcript-content').css("max-height", "250px");
        }, 0);
    })

    // element is a span so when Enter is pressed no action is taken, need to have it triggered manually
    $(document).on('keypress',function(e) {
        if(e.which == 13 && $('.p3sdk-interactive-transcript-collapse').is(":focus")) {
            $('.p3sdk-interactive-transcript-collapse').click();
        }
    });

    $(window).on('load', function() {
        $('.newsletter__sign-up .ext-extra-btn').on('click', function(e) {
            e.preventDefault();
    
            var $link = $(this).attr('href');
    
            if($link != null) {
                window.open($link, '_blank');
            }
    
        });
    });

    // Click to copy to clipboard
    $('.click-to-copy').click( function(e) {
        e.preventDefault();
        e.stopPropagation();
        var textArea;
        
        var isOS = function () {
            //can use a better detection logic here
            return navigator.userAgent.match(/ipad|iphone/i);
        }
        
        function createTextArea(text) {
            textArea = document.createElement('textArea');
            textArea.readOnly = true;
            textArea.contentEditable = true;
            textArea.value = text;
            document.body.appendChild(textArea);
        }
        
        var selectText = function () {
            var range, selection;
        
            if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
            } else {
            textArea.select();
            }
        }
        
        var copyTo = function () {
            document.execCommand('copy');
            document.body.removeChild(textArea);
        }
        
        createTextArea($('#'+$(this).attr('data-target')).text());
        selectText();
        copyTo();
        $(this).append('<span class="alert alert-success" role="alert">Copied!</span>');
        $(this).find('.alert').fadeOut( "slow" );
    });

    // Start with groups collapsed
    $('.page__toggle__content--collapsed').css({
        'height': 0,
        'overflow': 'hidden'
    });

    // Scroll to top when linked to element
    // Change current items parent group height to auto (opened)
    $(location.hash).parent('.page__toggle__content').height('auto');
    // Change current items parent group toggle
    $(location.hash).parent('.page__toggle__content').prev('.page__toggle').toggleClass('page__toggle--open page__toggle--close');
    if (location.href.indexOf('#') > -1) {
           // FireFox scroll bugfix
          location.href+='';
       }
    //on page load check if the hashed element exists and scroll to the element
    if($(location.hash).length === 1){
        Helpers.scrollToAnchor(location.hash);
    }

    // checks to see if element is in viewport
    $.fn.isInViewport = function() {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();

        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();

        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    // If footer is in viewport hide the Join Now button
    $(window).on('resize scroll', function() {
        if(window.innerWidth <= 768) {
            return $('footer').isInViewport() ? $('.mobileShow').hide() : $('.mobileShow').show();
        }
    });

    // When the user clicks on the button, scroll to the top of the document
    $('#backToTopBtn').click(function() {
    /* document.body.scrollTop = 0; // For Chrome, Safari and Opera 
        document.documentElement.scrollTop = 0; // For IE and Firefox*/
        $('html, body').animate({ scrollTop: 0 }, 'slow', function() {

        });
    });
    
    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function() { scrollFunction() };
    
    function scrollFunction() {
        if (document.body.scrollTop > 250 || document.documentElement.scrollTop > 250) {
            document.getElementById("backToTopBtn").style.display = "block";
        } else {
            document.getElementById("backToTopBtn").style.display = "none";
        }
    }
});

//When a click event happens on a link that has a href value that starts with "#"
$('a[href^="#"]').on('click', function (e) {
    if ($(e.target).parents('.accordion-link').length || ($(e.target).is( "a" ) && $(e.target).attr('href') !== "#")) {
        e.preventDefault();
		//add hash back to url
        if (!$($(e.target).attr('href')).hasClass('panel-collapse')) {
            location.hash = $(e.target).attr('href');
            Helpers.scrollToAnchor(location.hash);
        }
    }
});

/* Toggle All Button */
Page.prototype.onToggleAllClick = function(e){
    e.preventDefault();
    e.stopPropagation();

    // If the group is closed open it... else close it (set height property)
    if ( $(e.currentTarget).hasClass('page__toggle__all-button--open') ) {

        // Hide item count
        this.$togglesItemCount.addClass('page__toggle__items-count--hidden');

        // Set content height to auto
        this.$toggleContent.height('auto');
        this.$toggleContent.removeClass('page__toggle__content--collapsed');
        this.$toggleContent.addClass('page__toggle__content--opened');

        // Change button label from Open All to Close All
        this.$toggles.addClass('page__toggle--close');
        this.$toggles.removeClass('page__toggle--open');

        // Change html text to say "Close All"
        $(e.currentTarget).html('Close All <span class="page__toggle__all-button__sign"></span>');

        // Change all single group toggles from Open to Close
        $(e.currentTarget).toggleClass('page__toggle__all-button--open page__toggle__all-button--close');

    } else {
        // Show item count
        this.$togglesItemCount.removeClass('page__toggle__items-count--hidden');

        // Set content height to 0
        this.$toggleContent.height(0);
        this.$toggleContent.removeClass('page__toggle__content--opened');
        this.$toggleContent.addClass('page__toggle__content--collapsed');

        // Change button label from Close All to Open All
        this.$toggles.removeClass('page__toggle--close');
        this.$toggles.addClass('page__toggle--open');

        // Change html text to say "Open All"
        $(e.currentTarget).html('Open All <span class="page__toggle__all-button__sign"></span>');

        // Change all single group toggles from Close to Open
        $(e.currentTarget).toggleClass('page__toggle__all-button--open page__toggle__all-button--close');

    }

};

/* Toggle Single Group of Items(FAQ) */
Page.prototype.onToggleClick = function(e){
    e.preventDefault();
    e.stopPropagation();

    var _self = this;
    var scrollHeight = 0;

    // If the group is closed open it... else close it (animate height property)
    if ( $(e.currentTarget).hasClass('page__toggle--open') ) {

        // Animate content height to auto
        $(e.currentTarget).parent().find('.page__toggle__content').animate({
            height: $(e.currentTarget).parent().find('.page__toggle__content').get(0).scrollHeight
        }, 300, "linear", function() {
            $(this).height('auto');
        });

        // Hide item count
        $(e.currentTarget).parent().find('.page__toggle__items-count').addClass('page__toggle__items-count--hidden');

        // Change Single Group Toggle from Open to Close
        $(e.currentTarget).toggleClass('page__toggle--open page__toggle--close');

        // Collapse / Open Page Toggle Content
        $(e.currentTarget).parent().find('.page__toggle__content').toggleClass('page__toggle__content--open page__toggle__content--collapsed');
    } else {

        // Animate content height to 0 and show item count at the end
        $(e.currentTarget).parent().find('.page__toggle__content').animate({
            height: 0
        }, 300, "linear", function() {
            $(this).parent().find('.page__toggle__items-count').removeClass('page__toggle__items-count--hidden');
        });

        // Change Single Group Toggle from Close to Open
        $(e.currentTarget).toggleClass('page__toggle--open page__toggle--close');

        // Collapse / Open Page Toggle Content
        $(e.currentTarget).parent().find('.page__toggle__content').toggleClass('page__toggle__content--open page__toggle__content--collapsed');

    }

};



Page.MODULE_CLASS = 'page';

module.exports = Page;
