'use strict';

var app = require('../core/app');

/**
 * Modal component
 *
 * @param $el
 * @param options
 * @constructor
 */
function Modal($el, options){
    this.$el = jQuery($el);
    this.options = jQuery.extend({}, Modal.DEFAULT, options);
    this.initModal();
}

/**
 * Init Modal
 */
Modal.prototype.initModal = function() {

    this.$modal = jQuery('<div/>');
    this.$modal.addClass(Modal.CLASS);

    this.$wrapper = jQuery('<div/>');
    this.$wrapper.addClass(Modal.CLASS_WRAPPER);

    this.$content = jQuery('<div/>');
    this.$content.addClass(Modal.CLASS_CONTENT);

    this.$close = jQuery('<span data-action="close" aria-label="Close Video" tabindex="0"/>');
    this.$close.addClass(Modal.CLASS_CLOSE);

    this.$content.html(this.$el);
    this.$content.append(this.$close);
    this.$wrapper.html(this.$content);
    this.$modal.html(this.$wrapper);
    this.$modal.hide();
    app.body.append(this.$modal);

    this.setWidth(this.options.width);

    this.showModal();

    this.initEvents();

    if(this.options.video){
        if(!this.options.threeplay) {
            this.$content.addClass('modal__content--video');
        }
    }

    if(this.options.extraClass){
        this.$modal.addClass(this.options.extraClass);
    }

};

Modal.prototype.initEvents = function(){
    this.$modal.find('[data-action="close"]').on('click', jQuery.proxy(this.onCloseClick, this));
    this.$modal.on('click', jQuery.proxy(this.onModalClick, this));
    $('.modal__close').focus();
};

Modal.prototype.onCloseClick = function(e){
    e.preventDefault();
    this.hideModal();
};

Modal.prototype.onModalClick = function(e){

    if(jQuery(e.target).is(this.$modal)){
        e.preventDefault();
        this.hideModal();
    }
};

Modal.prototype.setWidth = function(width){
    this.$wrapper.css('max-width',width);
};

Modal.prototype.showModal = function(){

    if(!this.$el.is(':visible')){

        app.body.css('overflow', 'hidden');
        this.$content.hide();
        this.$modal.show();
        this.$modal.addClass(Modal.CLASS_OPEN);
        this.$modal.attr("tabindex", "0");
        this.$modal.focus();
        var $self = this;
        setTimeout(function(){
            $self.$content.fadeIn(300);
        }, 500);

        $("iframe.embed-responsive-item").wrap('<div class="embed-responsive embed-responsive-16by9"></div>');

            setTimeout(function(){
                $('.p3sdk-target .p3sdk-interactive-transcript-collapse').keypress(function(event){
                    if(event.keyCode == 13){
                        $('.p3sdk-interactive-transcript-collapse').click();
                    }
                });

                $('.modal__close').keypress(function(event){
                    if(event.keyCode == 13){
                        $('.modal__close').click();
                    }
                });
            },
            1000);

    }

};

Modal.prototype.hideModal = function(cb){

    var $self = this;

    this.$content.fadeOut(300, function(){
        app.body.css('overflow', '');
        $self.$modal.removeClass(Modal.CLASS_OPEN);
        $self.$modal.hide();
        if($self.options.destroy){
            $self.$modal.remove();
        }
        $self.$el.trigger('close.modal');
        
    });
};   

Modal.CLASS = 'modal';

Modal.CLASS_OPEN = 'modal--open';
Modal.CLASS_WRAPPER = 'modal__wrapper';
Modal.CLASS_CONTENT = 'modal__content';
Modal.CLASS_CLOSE= 'modal__close';

Modal.DEFAULT = {
    top: 'auto',
	destroy: false,
    video: false,
    extraClass: '',
    threeplay: false
};

module.exports = Modal;
