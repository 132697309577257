'use strict';

function View($el){
    this.$el = $el;
    this.$loadMore = this.$el.find('.pager-load-more .pager-next a');
    this.$loadMore.unbind().on('click', $.proxy(this.onLoadMoreClick, this));
}

View.MODULE_CLASS = 'view';

module.exports = View;
