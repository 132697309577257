'use strict';


function LoadMore($el){
    this.$el = $el;
    this.masonry = this.$el.closest('.view').find('.masonry-grid').data('instance');

    this.currentLink = '';

    this.$el.on('click', 'a', $.proxy(this.load, this));
    
}

LoadMore.prototype.getLink = function(){
    return this.$el.find('a').attr('href');
}

LoadMore.prototype.updateLink = function(link){
    if(link) {
        // If has next add it to load more
        this.$el.find('a').attr('href', link);
    } else {
        // If no next remove link
        this.$el.find('a').attr('href', '');

        // Remove fade out and load more link
        $('.feeds').removeClass('feeds--load-more');
        $('.feeds').css('padding-bottom', 75);
        $('.pager').hide();
    }
};

/* Load More (Masonry Feed) */
LoadMore.prototype.load = function(e){
    e.preventDefault();
    var link = this.getLink();
    var _self = this;

    // If there's a next link get the content and append to masonry
    if(link) {
        $.get(link, function( result ) {

            var $html = $(result);
            // wrap content in jQuery object
            var $feeds = $html.find('.masonry-grid__item');
            var link = $html.find('.pager-load-more a').attr('href');

            // add jQuery object
            _self.masonry.appendFeeds($feeds);
            _self.updateLink(link);
        });
    }
};

LoadMore.MODULE_CLASS = 'pager-load-more';

module.exports = LoadMore;
