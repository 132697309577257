'use strict';
var Modal = require('./modal');

function Newsletter($el){
    this.$el = $el;
    this.$el.on('click', $.proxy(this.onSignupClick, this));
}

Newsletter.prototype.onSignupClick = function(e){
    e.preventDefault();

    var embedHtml = '<iframe class="newsletter__iframe" src="'+Newsletter.URL+'" frameborder="0" title="Email Newsletter Subscription Form"><a href="'+Newsletter.URL+'">Please subscribe to our newsletter</a></iframe>';
    this.modal = new Modal(embedHtml, {
        destroy:true,
        extraClass: 'newsletter__iframe__content'
    });
};

Newsletter.URL = 'https://service.govdelivery.com/accounts/USNIH/subscriber/new?topic_id=USNIH_95';
Newsletter.MODULE_CLASS = 'newsletter__sign-up';

module.exports = Newsletter;
