/**
 * Biolist Show All Fix
 * @file
 * this file fixes static HTML biolist using readmore js
 */
(function ($) {
  Drupal.behaviors.biolist = {
    attach: function (context) {
      if (typeof readmore === "function") {
        $('#static-related-members-block', context).readmore({
          moreLink: '<a href="#"><span>SEE ALL<span></a>',
          lessLink: '',
          collapsedHeight: 1030
        });
      }
    }
  };
})(jQuery);