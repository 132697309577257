'use strict';

function Homepage($el){
    this.$el = $el;
    this.$arrow = this.$el.find('.js--go_down');
    this.arrowPositionSwitchAfter = '';
    this.arrowScrollTop = this.$arrow.scrollTop();
    this.arrowPositionSwitch = false;

    this.$arrow.on('click', $.proxy(this, 'onDownClick'));
    $(window).on('scroll', $.proxy(this, 'onWindowScroll'));

    this.initWaypoints();
}

Homepage.prototype.arrowTransition = function(arrowPositionSwitch){
    var _self = this;
    if(this.arrowPositionSwitch !== this.arrowPositionSwitchAfter) {
        if (this.arrowPositionSwitch === 'fixed') {
            this.$arrow.fadeOut('fast', function() {
                _self.$arrow.css('position', 'fixed');
                _self.$arrow.fadeIn();
            });

        } else if (this.arrowPositionSwitch === 'absolute') {
            this.$arrow.fadeOut('fast', function() {
                _self.$arrow.css('position', 'absolute');
                _self.$arrow.fadeIn();
            });

        }
    }

    this.arrowPositionSwitchAfter = this.arrowPositionSwitch;
};

Homepage.prototype.onWindowScroll = function(arrowPositionSwitch){
    if ($(window).scrollTop() > this.arrowScrollTop) {
        this.arrowPositionSwitch = 'fixed';
    } else if($(window).scrollTop() <= this.arrowScrollTop) {
        this.arrowPositionSwitch = 'absolute';
    }

    this.arrowTransition(this.arrowPositionSwitch);
};

Homepage.prototype.initWaypoints = function(){

    // Arrow
    var arrow = this.$arrow;


    if($(window).scrollTop() > this.arrowScrollTop) {
        this.arrowPositionSwitch = 'fixed';
        this.arrowTransition(this.arrowPositionSwitch);
    }

    // Section 2
    $('.section-2').waypoint(function(direction) {
        if (direction === "down") {
            arrow.attr('data-section', 3);
        } else {
            arrow.attr('data-section', 2);
        }
    }, {
        offset: '135px;'
    });

    // Section 3
    $('.section-3').waypoint(function(direction) {
        if (direction === "down") {
            arrow.attr('data-section', 4);
        } else {
            arrow.attr('data-section', 3);
        }
    }, {
        offset: '135px;'
    });

    // Section 4
    $('.section-4').waypoint(function(direction) {
        if (direction === "down") {
            arrow.fadeOut();
        } else {
            arrow.fadeIn();
        }
    }, {
        offset: '135px;'
    });

};


Homepage.prototype.onDownClick = function(){
    var section = '.section-' + this.$arrow.attr('data-section').toString();
    var nextSection = (parseInt(this.$arrow.attr('data-section'), 10) + 1).toString();

    $('html, body').animate({
        scrollTop: this.getPosition(section)
    }, 1000, function() {
        if(nextSection < 5) {
            $('.js--go_down').attr('data-section', nextSection);
        } else if(nextSection === 5) {
            $('.js--go_down').fadeOut();
        }
    });
};

Homepage.prototype.getPosition = function(section){
    return this.$el.find(section).position().top - 134;
};


Homepage.MODULE_CLASS = 'homepage';

module.exports = Homepage;