'use strict';

var Helpers = require('../core/helpers');
var Modal = require('./modal');

function Hero($el){
    this.$el = $el;
    this.$play = this.$el.find('.hero__button');

    this.$play.on('click', $.proxy(this.onPlayClick, this));
}

Hero.prototype.onPlayClick = function(e){

    var videoUrl = this.$play.attr('href');
    var videoId = Helpers.getYoutubeUrl(videoUrl);

    if(videoId){
        e.preventDefault();
        var embedHtml = '<iframe src="https://www.youtube.com/embed/'+videoId+'" frameborder="0" allowfullscreen></iframe>';
        this.modal = new Modal(embedHtml, {
            destroy:true,
            video: true
        });
    }
};

Hero.MODULE_CLASS = 'hero';

module.exports = Hero;
