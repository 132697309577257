'use strict';

var Masonry = require('masonry-layout');

function MasonryGrid($el){
    this.$el = $el;

    this.initMasonry();
}

MasonryGrid.prototype.initMasonry = function(){
    this.msnry = new Masonry(this.$el[0], {
    itemSelector: '.masonry-grid__item',
    horizontalOrder: true
})
};

MasonryGrid.prototype.getPosition = function(){
    return this.$el.position().top + this.$el.outerHeight(true);
};

/* Load More (Masonry Feed) */
MasonryGrid.prototype.appendFeeds = function($feeds){
    this.$el.append($feeds);
    this.msnry.appended( $feeds );
};

MasonryGrid.MODULE_CLASS = 'masonry-grid';

module.exports = MasonryGrid;