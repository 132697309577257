'use strict';

require('browsernizr/test/css/mediaqueries');
var Modernizr = require('browsernizr');

function AriaMenu($el){
    this.$el = $el;
    this.$top_level_links = this.$el.find('> li > a');
    this.$links = this.$top_level_links.parent('li').find('ul').find('a');

    this.initAriaMenu();
}

AriaMenu.prototype.initAriaMenu = function(settings){

    var _self = this;

    settings = jQuery.extend({
        menuHoverClass: 'link--toggled',
    }, settings);

    // Add ARIA role to menubar and menu items
    this.$el.attr('role', 'menubar').find('li').attr('role', 'menuitem');

    // Set tabIndex to -1 so that top_level_links can't receive focus until menu is open
    this.$top_level_links.next('ul')
        .attr('data-test','true')
        .attr({ 'aria-hidden': 'true', 'role': 'menu' })
        .find('a')
        .attr('tabIndex',-1);

    // Adding aria-haspopup for appropriate items
    this.$top_level_links.each(function(){
        if($(this).next('ul').length > 0)
            $(this).parent('li').attr('aria-haspopup', 'true');
    });

    // On Hover
    this.topLevelLinksHover(settings);

    // Bind Keys
    this.bindKeysForTopLevelLinks(settings);

    this.bindKeysForSubmenuLinks(settings);

    // Hide menu if click or focus occurs outside of navigation
    this.$el.find('a').last().keydown(function(e){

        // TAB key
        if(e.keyCode == 9) {

            // If the user tabs out of the navigation hide all menus
            $('.' + settings.menuHoverClass)
                .attr('aria-hidden', 'true')
                .removeClass(settings.menuHoverClass)
                .find('a')
                .attr('tabIndex', -1);

            if ( Modernizr.mq('(max-width: 991px)') ) {
                _self.closeMobileMenu();
            }
        }
    });
    $(document).click(function(e){
        $('.' + settings.menuHoverClass)
            .attr('aria-hidden', 'true')
            .removeClass(settings.menuHoverClass)
            .find('a').attr('tabIndex', -1);
    });

    // On Click
    this.onClick();

};

AriaMenu.prototype.topLevelLinksHover = function(settings){
    this.$top_level_links.on('hover', function () {
        if ( Modernizr.mq('(min-width: 992px)') ) {
            $(this).closest('ul')
                .attr('aria-hidden', 'false')
                .find('.' + settings.menuHoverClass)
                .attr('aria-hidden', 'true')
                .removeClass(settings.menuHoverClass)
                .find('a')
                .attr('tabIndex', -1);
            $(this).next('ul')
                .attr('aria-hidden', 'false')
                .addClass(settings.menuHoverClass)
                .find('a').attr('tabIndex', 0);
        }
    });
};

AriaMenu.prototype.bindKeysForTopLevelLinks = function(settings){
    // Bind arrow keys for navigation
    this.$top_level_links.keydown(function(e){

        // Left Arrow
        if(e.keyCode == 37) {
            e.preventDefault();
            // This is the first item
            if($(this).parent('li').prev('li').length == 0) {
                $(this).parents('ul').find('> li').last().find('a').first().focus();
            } else {
                $(this).parent('li').prev('li').find('a').first().focus();
            }
        }

        // Up Arrow
        else if(e.keyCode == 38) {
            e.preventDefault();
            if($(this).parent('li').find('ul').length > 0) {
                $(this).parent('li').find('ul')
                    .attr('aria-hidden', 'false')
                    .addClass(settings.menuHoverClass)
                    .find('a').attr('tabIndex',0)
                    .last().focus();
            }
        }

        // Right Arrow
        else if(e.keyCode == 39) {
            e.preventDefault();
            // This is the last item
            if($(this).parent('li').next('li').length == 0) {
                $(this).parents('ul').find('> li').first().find('a').first().focus();
            } else {
                $(this).parent('li').next('li').find('a').first().focus();
            }
        }

        // Down Arrow
        else if(e.keyCode == 40) {
            e.preventDefault();
            if($(this).parent('li').find('ul').length > 0) {
                $(this).parent('li').find('ul')
                    .attr('aria-hidden', 'false')
                    .addClass(settings.menuHoverClass)
                    .find('a').attr('tabIndex',0)
                    .first().focus();
            }
        }

        // Enter or Space
        else if(e.keyCode == 13 || e.keyCode == 32) {
            e.preventDefault();

            // If submenu is opened, follow link
            if( $(this).parent('li').find('ul[aria-hidden=false]').length || !$(this).parent('li').find('ul.submenu').length ) {
                window.location.href = $(this).attr('href');
            }

            // If submenu is hidden, open it
            $(this).parent('li').find('ul[aria-hidden=true]')
                .attr('aria-hidden', 'false')
                .addClass(settings.menuHoverClass)
                .find('a').attr('tabIndex',0)
                .first().focus();
                
            if(!$(this).find('.mobile-menu-dropdown-btn-icon').hasClass('flip')){
                $(this).find('.mobile-menu-dropdown-btn-icon').addClass('flip');
            }
            
        }

        // Escape
        else if(e.keyCode == 27) {
            e.preventDefault();
            $('.'+settings.menuHoverClass)
                .attr('aria-hidden', 'true')
                .removeClass(settings.menuHoverClass)
                .find('a')
                .attr('tabIndex',-1);
            if($(this).find('.mobile-menu-dropdown-btn-icon').hasClass('flip')){
                $(this).find('.mobile-menu-dropdown-btn-icon').removeClass('flip');
            }
            
        }

        else {
            $(this).parent('li').find('ul[aria-hidden=false] a').each(function(){
                if($(this).text().substring(0,1).toLowerCase() == AriaMenu.KEY_CODEMAP[e.keyCode]) {
                    $(this).focus();
                    return false;
                }
            });
        }
    });
};
/**
 * PMI 162
 * Add support for ontouchstart of the new i tag to open and close the submenu.
 * Open and close appears to be powered by adding the "link--touched" class to the ul tag with the class "submenu".
 */
AriaMenu.prototype.onClick = function(){

    // Top Level
    this.$top_level_links.on('click ontouchstart', function (e) {
        e.stopImmediatePropagation();
        if (Modernizr.mq('(max-width: 991px)')) {
            e.preventDefault();
            if ((e.target.tagName).toLowerCase() !== "a" && $(e.currentTarget).parent().hasClass('has_children')) {
                if ($(e.currentTarget).next('.submenu').hasClass('link--touched')) {
                    if($(e.currentTarget).find('.mobile-menu-dropdown-btn-icon').hasClass('flip')){
                        $(e.currentTarget).find('.mobile-menu-dropdown-btn-icon').removeClass('flip');
                        $(e.currentTarget).next('.submenu').removeClass('link--touched link--toggled');
                    }
                }
                else if (!$(e.currentTarget).next('.submenu').hasClass('link--touched')) {
                    $(e.currentTarget).find('.mobile-menu-dropdown-btn-icon').addClass('flip');
                    $('.header__mainSection__menu > nav > ul.menu > li.has_children > a').next('.submenu').removeClass('link--touched');
                    $(e.currentTarget).next('.submenu').addClass('link--touched');
                }
            } else {
                window.location.href = $(e.currentTarget).attr('href');
            }
        }
    });

    // Submenu
    this.$links.on('click ontouchstart', function(e) {
        window.location.href = $(this).attr('href');
    });

}

AriaMenu.prototype.bindKeysForSubmenuLinks = function(settings){
    this.$links.keydown(function(e){

        // Prev
        if ( Modernizr.mq('(min-width: 992px)') ) {
            // Left Arrow
            var prevKey = 37;
        }

        if ( Modernizr.mq('(max-width: 991px)') ) {
            // Up Arrow
            var prevKey = 38;
        }

        if(e.keyCode == prevKey) {
            e.preventDefault();
            // This is the first item
            if($(this).parent('li').prev('li').length == 0) {
                $(this).parents('ul').parents('li').find('a').first().focus();
            } else {
                $(this).parent('li').prev('li').find('a').first().focus();
            }
        }

        // Next
        if ( Modernizr.mq('(min-width: 992px)') ) {
            // Right Arrow
            var nextKey = 39;
        }

        if ( Modernizr.mq('(max-width: 991px)') ) {
            // Down Arrow
            var nextKey = 40;
        }

        else if(e.keyCode == nextKey) {
            e.preventDefault();
            if($(this).parent('li').next('li').length == 0) {
                $(this).parents('ul').parents('li').find('a').first().focus();
            } else {
                $(this).parent('li').next('li').find('a').first().focus();
            }
        }

        // Up one level Escape or upLevelKey
        if ( Modernizr.mq('(min-width: 992px)') ) {
            // Up Arrow
            var upLevelKey = 38;
        }

        if ( Modernizr.mq('(max-width: 991px)') ) {
            // Up Arrow
            var upLevelKey = 37;
        }

        else if(e.keyCode == 27 || e.keyCode == upLevelKey) {
            e.preventDefault();
            $(this)
                .parents('ul').first()
                .prev('a').focus()
                .parents('ul').first().find('.'+settings.menuHoverClass)
                .attr('aria-hidden', 'true')
                .removeClass(settings.menuHoverClass)
                .find('a')
                .attr('tabIndex',-1);
        }

        // Space
        else if(e.keyCode == 13 || e.keyCode == 32) {
            e.preventDefault();
            window.location = $(this).attr('href');
        }

        else {
            var found = false;
            $(this).parent('li').nextAll('li').find('a').each(function(){
                if($(this).text().substring(0,1).toLowerCase() == AriaMenu.KEY_CODEMAP[e.keyCode]) {
                    $(this).focus();
                    found = true;
                    return false;
                }
            });

            if(!found) {
                $(this).parent('li').prevAll('li').find('a').each(function(){
                    if($(this).text().substring(0,1).toLowerCase() == AriaMenu.KEY_CODEMAP[e.keyCode]) {
                        $(this).focus();
                        return false;
                    }
                });
            }
        }
    });
};

AriaMenu.prototype.closeMobileMenu = function() {
    var mobileMenuToggle = $('.header__mainSection__logo > .hamburger > a');

    if (mobileMenuToggle.hasClass('button--toggled')) {        
        mobileMenuToggle.removeClass('button--toggled');
        $('.header__mainSection__menu ul.menu').css({
            "opacity": "0",
            "height": "0"
        });
        $('body').removeClass('body--noscroll');
    }
}


AriaMenu.MODULE_CLASS = 'aria-menu';
AriaMenu.KEY_CODEMAP = {
    48:"0", 49:"1", 50:"2", 51:"3", 52:"4", 53:"5", 54:"6", 55:"7", 56:"8", 57:"9", 59:";",
    65:"a", 66:"b", 67:"c", 68:"d", 69:"e", 70:"f", 71:"g", 72:"h", 73:"i", 74:"j", 75:"k", 76:"l",
    77:"m", 78:"n", 79:"o", 80:"p", 81:"q", 82:"r", 83:"s", 84:"t", 85:"u", 86:"v", 87:"w", 88:"x", 89:"y", 90:"z",
    96:"0", 97:"1", 98:"2", 99:"3", 100:"4", 101:"5", 102:"6", 103:"7", 104:"8", 105:"9"
};

module.exports = AriaMenu;
