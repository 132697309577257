'use strict';

global.$ = require('jquery');
require('./core/lib/jquery.waypoints');

var app = require('./core/app');
[

    require('./modules/header'),
    require('./modules/hero'),
    require('./modules/masonry-grid'),
    require('./modules/page'),
    require('./modules/newsletter'),
    require('./modules/view'),
    require('./modules/loadMore'),
    require('./modules/homepage'),
    require('./modules/logos'),
    require('./modules/feed'),
    require('./modules/video-button'),
    require('./modules/aria-menu'),
    require('./modules/biolist'),
    require('./modules/elements/map-config'),
    require('./modules/elements/map-interact')

].forEach(function (module) {
    app.bind(module);
});
