'use strict';

require('browsernizr/test/css/mediaqueries');
var Modernizr = require('browsernizr');
// get the width of the current viewport
var currentPageWidth = $(window).width();

function Header($el){
    this.$el = $el;
    this.$mainSectionMenu = this.$el.find('.header__mainSection__menu');
    this.$mainSectionOther = this.$el.find('.header__mainSection__other');
    this.$mobileMenuToggle = this.$el.find('.header__mainSection__logo > .hamburger > a');
    this.$mobileSearchToggle = this.$el.find('.header__mainSection__logo > .searchbtn > a');
    this.$mobileSearchInput = this.$el.find('.custom-search-box');

    this.$mobileMenuToggle.on('click', $.proxy(this.onMobileMenuToggleClick, this));
    this.$mobileSearchToggle.on('click', $.proxy(this.onMobileSearchToggleClick, this));
    this.$mobileSearchToggle.on('keydown',$.proxy(this.onMobileSearchToggleKeydown, this));
    this.$mobileSearchInput.on('keydown',$.proxy(this.onMobileSearchInputKeydown, this));
    $(window).on('resize', $.proxy(this, 'onResize'));
    /*$(window).on('scroll', $.proxy(this, 'onScroll'));*/

    var currentPage = '';
    currentPage = window.location.pathname;

    jQuery(`.submenu li a[href="${currentPage}"]`).css('text-decoration', 'underline').css('text-decoration-color', '#262262').css('text-decoration-thickness', '2px').css('text-underline-offset', '3px');

    jQuery(`.submenu li a[href="${currentPage}"]`).parent().parent().parent().addClass('active_submenu');

    jQuery(`.menu li > a[href="${currentPage}"]`).parent().addClass('active_submenu');

    jQuery('.submenu li').removeClass('active_submenu');
    // add bottom padding to offset the height of the "JOIN NOW" button
    $('.menu.aria-menu').css('padding-bottom',$('.mobileShow').outerHeight(true));
}

/**************************************************************
 Hamburger Toggle
 **************************************************************/
Header.prototype.onMobileMenuToggleClick = function(e) {
    e.preventDefault();

    if( !this.$mobileMenuToggle.hasClass('button--toggled') ) {
        if( this.$mobileSearchToggle.hasClass('button--toggled') ) {
            this.closeMobileSearch();
        }    
        this.openMobileMenu();
    } else {
        this.closeMobileMenu();
    }
};

Header.prototype.openMobileMenu = function() {
    this.$mobileMenuToggle.addClass('button--toggled');
    this.$mainSectionMenu.addClass('header__mainSection__menu--active');
    $('body').addClass('body--noscroll');
};

Header.prototype.closeMobileMenu = function() {
    this.$mobileMenuToggle.removeClass('button--toggled');
    this.$mainSectionMenu.removeClass('header__mainSection__menu--active');
    $('body').removeClass('body--noscroll');
    if( !(this.$mainSectionMenu.css('opacity') == 1 || !this.$mainSectionOther.css('opacity') == 1) ) {
        $('body').removeClass('body--noscroll');
    }
};

/**************************************************************
 Search
 **************************************************************/
Header.prototype.onMobileSearchToggleClick = function(e) {
    e.preventDefault();

    if( !this.$mobileSearchToggle.hasClass('button--toggled') ) {
        if( this.$mobileMenuToggle.hasClass('button--toggled') ) {
            this.closeMobileMenu();
        }
        this.openMobileSearch();
    } else {
        this.closeMobileSearch();
    }
};

Header.prototype.openMobileSearch = function() {
    this.$mobileSearchToggle.addClass('button--toggled');
    this.$mainSectionOther.addClass('header__mainSection__other--active');
    $('body').addClass('body--noscroll');
};

Header.prototype.closeMobileSearch = function() {
    this.$mobileSearchToggle.removeClass('button--toggled');
    this.$mainSectionOther.removeClass('header__mainSection__other--active');
    if( !(this.$mainSectionMenu.css('opacity') == 1 || !this.$mainSectionOther.css('opacity') == 1) ) {
        $('body').removeClass('body--noscroll');
    }
};

/*
*  Mobile search tabindex
* */
Header.prototype.onMobileSearchToggleKeydown = function(e) {
    if( this.$mobileSearchToggle.hasClass('button--toggled') && Modernizr.mq('(max-width: 991px)') ) {

        // TAB key
        if( e.keyCode == 9 ) {
            e.preventDefault();

            this.$mobileSearchInput.focus();
        }
    }
}

Header.prototype.onMobileSearchInputKeydown = function(e) {
    if( this.$mobileSearchToggle.hasClass('button--toggled') && Modernizr.mq('(max-width: 991px)') ) {

        // TAB key
        if( e.keyCode == 9 ) {
            e.preventDefault();

            if (this.$mobileSearchInput.is(':focus')) {
                this.closeMobileSearch();
                this.$mobileMenuToggle.focus();
            }
        }
    }
}


/**************************************************************
 Window Resize fixes
 **************************************************************/
Header.prototype.onResize = function() {
    // only update things if the width of the page has resized. 
    if ($(window).width() !== currentPageWidth) {
        // update width stored.
        currentPageWidth = $(window).width();

        $('body').removeClass('body--noscroll');
        if ( Modernizr.mq('(min-width: 992px)') ) {

            // Remove no scroll
            $('body').removeClass('body--noscroll');

            // Reset mainSection menu
            this.$mainSectionMenu.removeClass('header__mainSection__menu--active');

            // Reset mainSection other
            this.$mainSectionOther.removeClass('header__mainSection__other--active');

            // Reset main menu toggled links
            $('.header__mainSection__menu ul.menu .submenu').removeClass('link--toggled link--touched');
        }

        if ( Modernizr.mq('(max-width: 991px)') ) {

            // Reset arrow direction
            $('i.arrow.opened').removeClass('opened');
            // Reset mainSection menu
            this.$mobileMenuToggle.removeClass('button--toggled');
            this.$mainSectionMenu.removeClass('header__mainSection__menu--active');

            // Reset mainSection other
            this.$mobileSearchToggle.removeClass('button--toggled');
            this.$mainSectionOther.removeClass('header__mainSection__other--active');

            // Reset main menu toggled links
            $('.header__mainSection__menu ul.menu .submenu').removeClass('link--toggled link--touched');
        }
    }
};

/**************************************************************
 Document Scroll Sticky Nav
 **************************************************************/
/*Header.prototype.onScroll = function() {
    if
    ($(window).scrollTop() > 100){
        this.$el.addClass("header--scrolled");
    }
    else
    {
        this.$el.removeClass("header--scrolled");
    }
};*/


Header.MODULE_CLASS = 'header';

module.exports = Header;
