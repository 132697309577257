﻿var GLOBAL_VAR;
var mapType; 
var map_config;

(function($, Drupal, undefined){
  Drupal.behaviors.usa_mapinteract = {

    attach: function(context, settings) {

    // Load map with initial settings based on JSON file
	$.fn.extend({
      	loadMap: function () {
      		var map_config = settings.usamap.variables;
			
			mapType = GLOBAL_VAR.map_config['default'].mapType;
			
			$(function() {
				var totalKeys = Object.keys(map_config).length - 1;
				for(var i = 1; i <= totalKeys; i++) {
					addEvent('map_'+i);
				}
				jQuery(window).on('load resize', function () {
					if(jQuery(window).width() < 992) {
						jQuery('#territories').hide();
						jQuery('#territorycontainer').find('.territory-info').show();
						jQuery('#accordion').show();
						jQuery('#mapwrapper').hide();
						jQuery('.map-accordion .toggle').show();
					}
					else {
						jQuery('#mapwrapper').show();
						jQuery('#territories').show();
						jQuery('#territorycontainer').find('.territory-info').hide();
						jQuery('.map-accordion .toggle').hide();
					}
				});

				if(!GLOBAL_VAR.map_config['map_52'].enable){
					$('.territories').hide();
				}

				var regionCount = getTotalRegions(GLOBAL_VAR.map_config);

				for(var i = 0; i < regionCount; i++) {
					var temp = i+1;
					if(typeof GLOBAL_VAR.map_config['default']['icon_region'][i].icon !== 'undefined') {
						$('#regions>polygon').after(parseSVG(
			        		'<image id="icon_map_'+temp+'" class="map-icons region-point" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="'+GLOBAL_VAR.map_config['default']['icon_region'][i].icon+'" x="'+GLOBAL_VAR.map_config['default']['icon_region'][i].x+'" y="'+GLOBAL_VAR.map_config['default']['icon_region'][i].y+'" height="'+GLOBAL_VAR.map_config['default']['icon_region'][i].height+'px" width="'+GLOBAL_VAR.map_config['default']['icon_region'][i].width+'px"></image>'
			    		));
					}
					else {
						$('#regions > image').remove();
					}
				}

      		})
      } 
	}) 

    $('#mapwrapper', context).loadMap();


	function addEvent(id,relationId){
		map_config = GLOBAL_VAR.map_config;

		var _obj = $('#'+id);
		var _Textobj = $('#'+id);
		var hoverDefault = map_config['default']['hoverDefault'];

		$('#'+['text-abb']).attr({'fill':map_config['default']['namesColor']});

		_obj.attr({'fill':map_config[id]['upColor'],'stroke':map_config['default']['borderColor']});
		_obj.css('background-color', map_config[id]['upColor']);

		_Textobj.attr({'cursor':'default'});
		if(map_config[id]['enable'] == true){
			
			_Textobj.attr({'cursor':'pointer'});
			_Textobj.hover(function(){
				//moving in/out effect
				$('#map-tip').show().html(map_config[id]['hover']);
				//if(hoverDefault)
				//	_obj.css({'fill':map_config[id]['overColor']})
			},function(){
				$('#map-tip').hide();
				//if(hoverDefault)
				//	$('#'+id).css({'fill':map_config[id]['upColor']});
			})
			//clicking effect
			_Textobj.mousedown(function(){
				$('#'+id).css({'fill':map_config[id]['downColor']});
			})
			_Textobj.mouseup(function(){
				$('#'+id).css({'fill':map_config[id]['overColor']});
				if(map_config[id]['target'] == 'new_window'){
					//window.open(map_config[id]['url']);	
				}else if(map_config[id]['target'] == 'same_window'){
					//window.parent.location.href=map_config[id]['url'];
				}
			})
			_Textobj.mousemove(function(e){
				var x=e.pageX+10, y=e.pageY+15;
				var tipw=$('#map-tip').outerWidth(), tiph=$('#map-tip').outerHeight(), 
				x=(x+tipw>$(document).scrollLeft()+$(window).width())? x-tipw-(20*2) : x
				y=(y+tiph>$(document).scrollTop()+$(window).height())? $(document).scrollTop()+$(window).height()-tiph-10 : y
				$('#map-tip').css({left:x, top:y})
			})
		}	
	}


	function dynamicAddEvent(id){
		var obj = $('#map_points_'+id);

		if(pin_config['points'][id]['enable'] == true){
			if (isTouchEnabled()) {
				obj.on('touchstart', function(e){
					var touch = e.originalEvent.touches[0];
					var x=touch.pageX+10, y=touch.pageY+15;
					var tipw=$('#map-tip').outerWidth(), tiph=$('#map-tip').outerHeight(),
					x=(x+tipw>$(document).scrollLeft()+$(window).width())? x-tipw-(20*2) : x
					y=(y+tiph>$(document).scrollTop()+$(window).height())? $(document).scrollTop()+$(window).height()-tiph-10 : y
					$('#'+id).css({'fill':pin_config['points'][id]['downColor']});
					$('#map-tip').show().html(pin_config['points'][id]['hover']);
					$('#map-tip').css({left:x, top:y})
				})
				obj.on('touchend', function(){
					$('#'+id).css({'fill':pin_config['points'][id]['upColor']});
					if(pin_config['points'][id]['target'] == 'new_window'){
						window.open(pin_config['points'][id]['url']);
					}else if(pin_config['points'][id]['target'] == 'same_window'){
						window.parent.location.href=pin_config['points'][id]['url'];
					}
				})
			}
			obj.attr({'cursor':'pointer'});
			obj.hover(function(){
				$('#map-tip').show().html(pin_config['points'][id]['hover']);
				obj.css({'fill':pin_config['points'][id]['overColor']})
			},function(){
				$('#map-tip').hide();
				obj.css({'fill':pin_config['points'][id]['upColor']});
			})
			//clicking effect
			obj.mousedown(function(){
				obj.css({'fill':pin_config['points'][id]['downColor']});
			})
			obj.mouseup(function(){
				obj.css({'fill':pin_config['points'][id]['overColor']});
				if(pin_config['points'][id]['target'] == 'new_window'){
					window.open(pin_config['points'][id]['url']);	
				}else if(pin_config['points'][id]['target'] == 'same_window'){
					window.parent.location.href=pin_config['points'][id]['url'];
				}
			})
			obj.mousemove(function(e){
					var x=e.pageX+10, y=e.pageY+15;
					var tipw=$('#map-tip').outerWidth(), tiph=$('#map-tip').outerHeight(), 
					x=(x+tipw>$(document).scrollLeft()+$(window).width())? x-tipw-(20*2) : x
					y=(y+tiph>$(document).scrollTop()+$(window).height())? $(document).scrollTop()+$(window).height()-tiph-10 : y
					$('#map-tip').css({left:x, top:y})
			})
		}	
	}

	$(window).load(function(){
	  mapLinkOpen();
	  // loadMap();
	});

	// Looping through regionObj to determine the
	// total number of regions. Returns count of totalregions.
	function getTotalRegions(regionObj) {
		var tempTotalRegions = [];

		for(var x in regionObj) {
			if(regionObj[x].region > 0) {
				tempTotalRegions.push(Math.max(regionObj[x].region));
			}
		}

		return Math.max.apply(this, tempTotalRegions);
	}

		function mapLinkOpen ()
		{
		  var hash = window.location.hash;
		  var clean = hash.replace(/[^a-zA-Z0-9 _]/g, '');

		  if(clean) {
		    var regionNumb = '.region'+clean;
		    $(regionNumb).trigger('click');
		  }
		}

		// Quick feature detection
		function isTouchEnabled() {
			return (('ontouchstart' in window)
				|| (navigator.MaxTouchPoints > 0)
				|| (navigator.msMaxTouchPoints > 0));
		}

		function parseSVG(s) {
	        var div= document.createElementNS('http://www.w3.org/1999/xhtml', 'div');
	        div.innerHTML= '<svg xmlns="http://www.w3.org/2000/svg">'+s+'</svg>';
	        var frag= document.createDocumentFragment();
	        while (div.firstChild.firstChild)
	            frag.appendChild(div.firstChild.firstChild);
	        return frag;
	    }

    }
  }
})(jQuery, Drupal);


