'use strict';
var Modal = require('./modal');
var flag = 0;

function Feed($el){
    this.$el = $el;
    this.$play = this.$el.find('.feed__play');
    //this.$play.on('click', $.proxy(this.onPlayClick, this));
    $( document ).off().on('click', '.feed__play, .feed__image-modal, .feed__title_play', $.proxy(this.onClick, this));
}

Feed.prototype.onClick = function(e){
    e.preventDefault();
    e.stopPropagation();

    var snippet = '';

    if( $(e.currentTarget).hasClass('feed__play') ) {

        // Video Modal
        var video = $(e.currentTarget).data('video');

        var media = $(e.currentTarget).data('media');
        var target = $(e.currentTarget).data('targetid');
        var playerid = $(e.currentTarget).data('playerid');
        snippet = $(e.currentTarget).data('snippet');

        var snippetExists = snippet.length ? true : false;



        if(video) {
            if(snippet) {
                var videoEmbedHtml = snippet;
            }
            else {
                var videoEmbedHtml = '<iframe src="https://www.youtube.com/embed/' + video + '" frameborder="0" allowfullscreen><a href="https://www.youtube.com/embed/' + video + '" target="_blank">Watch the video</a></iframe>';
            }

            this.modal = new Modal(videoEmbedHtml, {
                destroy: true,
                video: true,
                threeplay: snippetExists
            });

        }

    } else if( $(e.currentTarget).hasClass('feed__title_play') ) {

        // Video Modal
        var video = $(e.currentTarget).data('video');
        var media = $(e.currentTarget).data('media');
        var target = $(e.currentTarget).data('targetid');
        var playerid = $(e.currentTarget).data('playerid');
        snippet = $(e.currentTarget).data('snippet');

        var snippetExists = snippet.length ? true : false;


        if(video) {

            if(snippet) {
                var videoEmbedHtml = snippet;
            }
            else {
                var videoEmbedHtml = '<iframe src="https://www.youtube.com/embed/' + video + '" frameborder="0" allowfullscreen><a href="https://www.youtube.com/embed/' + video + '" target="_blank">Watch the video</a></iframe>';
            }
            
            this.modal = new Modal(videoEmbedHtml, {
                destroy: true,
                video: true,
                threeplay: snippetExists
            });
        }

    } else if( $(e.currentTarget).hasClass('feed__image-modal') ) {

        // Image Modal
        var imageEmbedHtml = $(e.currentTarget).clone();
        this.modal = new Modal(imageEmbedHtml, {
            destroy:true,
            extraClass: 'image-modal__iframe__content'
        });

    }

};

Feed.MODULE_CLASS = 'feed';

module.exports = Feed;
